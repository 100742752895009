/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// =======================
//    Styles
// =======================

// Normalize CSS (https://necolas.github.io/normalize.css/)
import 'normalize.css';

// Fontawesome 5 PRO (https://fontawesome.com/)
import './src/libs/fontawesome/css/fontawesome.min.css';
import './src/libs/fontawesome/css/regular.min.css';
import './src/libs/fontawesome/css/light.min.css';

// Hamburgers (https://jonsuh.com/hamburgers/)
import './src/styles/vendor-settings/hamburgers.scss';

// SwiperJS (https://swiperjs.com/)
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';

import './src/styles/styles.scss';

// =======================
//    Code
// =======================
import SwiperCore, { Autoplay, Keyboard, Navigation, Pagination, Parallax } from 'swiper';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// 3rd party libraries set-up
SwiperCore.use([Parallax, Autoplay, Navigation, Pagination, Keyboard]);
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

// Smooth scroll for URL fragments
export const onRouteUpdate = ({ location }) => {
	if (location?.hash?.length > 0) {
		const fragment = location?.hash?.replace('#', '');
		const destEl = document?.querySelector(`[data-navigation-id=${fragment}]`);

		if (destEl !== null) {
			let offsetTop = 0;
			let tempEl = destEl;
			while (tempEl) {
				offsetTop += tempEl?.offsetTop;
				tempEl = tempEl?.offsetParent;
			}

			gsap.to(window, {
				duration: Math.max(1.21, Math.log(Math.abs(offsetTop - window?.scrollY) / 2000)),
				scrollTo: {
					y: Math.max(0, offsetTop),
					autoKill: true,
				},
				ease: 'power3.out',
			});
		}
	}
};
